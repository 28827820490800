import { HiOutlineUserGroup, HiUserGroup } from '@/Icons/hi';
import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from '@/Icons/md';
import { PiAirplaneInFlightDuotone, PiAirplaneInFlightFill } from '@/Icons/pi';
import { RiHotelFill, RiHotelLine } from '@/Icons/ri';

export const categoriesList = [
  {
    id: 1,
    name: 'Flight',
    code: 'flight',
    role: false,
    icon: <PiAirplaneInFlightDuotone className="text-xl md:text-2xl" />,
    activeIcon: <PiAirplaneInFlightFill className="text-xl md:text-2xl" />,
  },
  {
    id: 2,
    name: 'Hotel',
    code: 'hotel',
    role: false,
    icon: <RiHotelLine className="text-xl md:text-2xl" />,
    activeIcon: <RiHotelFill className="text-xl md:text-2xl" />,
  },
  {
    id: 3,
    name: 'Visa',
    code: 'visa',
    icon: <HiOutlineUserGroup className="text-xl md:text-2xl" />,
    activeIcon: <HiUserGroup className="text-xl md:text-2xl" />,
  },
  {
    id: 6,
    name: 'Fare',
    code: 'fare',
    icon: <HiOutlineUserGroup className="text-xl md:text-2xl" />,
    activeIcon: <HiUserGroup className="text-xl md:text-2xl" />,
    role: true,
  },
];

export const flightsList = [
  {
    id: 1,
    name: 'One Way',
    code: 'oneway',
    icon: <MdOutlineRadioButtonUnchecked className="text-base" />,
    activeIcon: <MdOutlineRadioButtonChecked className="text-base" />,
  },
  {
    id: 2,
    name: 'Round Trip',
    code: 'return',
    icon: <MdOutlineRadioButtonUnchecked className="text-base" />,
    activeIcon: <MdOutlineRadioButtonChecked className="text-base" />,
  },
  {
    id: 3,
    name: 'Multi City',
    code: 'multicity',
    icon: <MdOutlineRadioButtonUnchecked className="text-base" />,
    activeIcon: <MdOutlineRadioButtonChecked className="text-base" />,
  },
];

export const fareFlightList = [
  {
    id: 1,
    name: 'One Way',
    code: 'oneway',
    icon: <MdOutlineRadioButtonUnchecked className="text-base" />,
    activeIcon: <MdOutlineRadioButtonChecked className="text-base" />,
  },
  {
    id: 2,
    name: 'Round Trip',
    code: 'return',
    icon: <MdOutlineRadioButtonUnchecked className="text-base" />,
    activeIcon: <MdOutlineRadioButtonChecked className="text-base" />,
  },
  // {
  //   id: 3,
  //   name: 'Multi City',
  //   code: 'multicity',
  //   icon: <MdOutlineRadioButtonUnchecked className="text-base" />,
  //   activeIcon: <MdOutlineRadioButtonChecked className="text-base" />,
  // },
];

export const bannerList = [
  {
    id: 1,
    name: 'banner1',
    img: '/banner1.png',
  },
  {
    id: 2,
    name: 'banner2',
    img: '/banner2.png',
  },
  {
    id: 3,
    name: 'banner3',
    img: '/banner3.png',
  },
  {
    id: 4,
    name: 'banner4',
    img: '/banner4.png',
  },
  {
    id: 5,
    name: 'banner5',
    img: '/banner5.png',
  },
];
